<template>
  <div id="connection-layout">
    <div id="toolbar">
      <div class="logo">
        <img :src="logo" alt="logo" :height="logoHeight" />
      </div>
    </div>
    <div class="row">
      <Authentication>
        <div id="db-connect">
          <div class="title">Database Connection</div>

          <ValidationObserver ref="form">
            <ValidationProvider
              v-slot="{ errors }"
              name="server name"
              :rules="{ required: true }"
            >
              <TextField
                v-model="servername"
                label="server name"
                is-mandatory
                :auto-focus="true"
                :is-readonly="isLoadingTest || isLoadingDatabase"
                :error="errors[0]"
                class="q-mb-md"
                @enter="validate"
              />
            </ValidationProvider>
            <ValidationProvider
              v-slot="{ errors }"
              name="username"
              :rules="{ required: true }"
            >
              <TextField
                v-model="username"
                label="username"
                is-mandatory
                :auto-focus="true"
                :is-readonly="isLoadingTest || isLoadingDatabase"
                :error="errors[0]"
                class="q-mb-md"
                @enter="validate"
              />
            </ValidationProvider>
            <Password
              v-model="password"
              :is-readonly="isLoadingTest || isLoadingDatabase"
              @enter="validate"
            />
          </ValidationObserver>

          <div class="row items-center">
            <q-space />

            <BaseButton
              label="Test Connection"
              :is-loading="isLoadingTest"
              class="test-in-btn"
              @click="validate"
            />
          </div>
          <ValidationProvider
            v-slot="{ errors }"
            name="Database Name"
            :rules="{ required: true }"
          >
            <TextField
              v-model="dbname"
              label="Database Name"
              is-mandatory
              :auto-focus="true"
              :is-readonly="isLoadingTest || isLoadingDatabase"
              :error="errors[0]"
              class="q-mb-md"
            />
          </ValidationProvider>
          <BaseButton
            label="Connect"
            :is-loading="isLoadingDatabase"
            class="connect-btn"
            @click="updateConnection"
          />

          <FormFieldError v-if="error" :error="error" class="text-center" />
        </div>
      </Authentication>
    </div>

    <template v-if="testSuccess">
      <Confirm
        :value="testSuccess"
        icon="eva-checkmark-outline"
        icon-color="green"
        @input="testSuccess = false"
      >
        <template #title> Test Connection Status </template>

        <template #default>
          <div class="q-py-md">
            <p>
              Test Connection Successfully.<br />
              Please continue with database connection!
            </p>
          </div>
        </template>

        <template #footer>
          <BaseButton label="Ok" color="primary" @click="testSuccess = false" />
        </template>
      </Confirm>
    </template>
    <template v-if="testFailure">
      <Confirm
        :value="testFailure"
        icon="eva-close-outline"
        icon-color="red"
        @input="testFailure = false"
      >
        <template #title> Test Connection Failed </template>

        <template #default>
          <div class="q-py-md">
            <p>
              Test connection failed. <br />
              Please check the provided connection details and reconnect again.
            </p>
          </div>
        </template>

        <template #footer>
          <BaseButton label="Ok" color="primary" @click="testFailure = false" />
        </template>
      </Confirm>
    </template>
    <template v-if="databaseSuccess">
      <Confirm
        :value="databaseSuccess"
        icon="eva-checkmark-outline"
        icon-color="green"
        @input="successReload"
      >
        <template #title> Database Connected </template>

        <template #default>
          <div class="q-py-md">
            <p>
              Congratulations! The application has successfully connected to the
              database. You're all set to use the application's features.
            </p>
          </div>
        </template>
        <template #footer>
          <BaseButton label="Ok" color="primary" @click="successReload" />
        </template>
      </Confirm>
    </template>

    <div class="footer">&copy; 2024 All Rights Reserved</div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import FormFieldError from "@/components/common/form/FormFieldError.vue";
import Password from "./components/Password.vue";
import { ValidationProvider } from "vee-validate";
import TextField from "@/components/common/form/text-field/TextField.vue";
import { auth } from "@/api/factory.js";
import Confirm from "@/components/common/popup/Confirm.vue";
import Authentication from "@/layouts/auth/components/Authentication.vue";

export default {
  name: "Connect",

  components: {
    ValidationObserver,
    Password,
    FormFieldError,
    ValidationProvider,
    TextField,
    Confirm,
    Authentication,
  },

  data() {
    return {
      servername: "",
      username: "",
      password: "",
      dbname: "",
      rememberMe: false,
      isLoadingTest: false,
      isLoadingDatabase: false,
      error: "",
      testSuccess: false,
      testFailure: false,
      databaseSuccess: false,
    };
  },

  computed: {
    logo() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return require(`@/assets/logo/sobha-logo.png`);
      } else {
        return require(`@/assets/logo/logo-${
          this.darkTheme ? "dark" : "light"
        }.png`);
      }
    },

    logoHeight() {
      let origin = location.origin;
      if (
        origin === "https://edmsuat.sobhaapps.com" ||
        origin === "https://edms.sobhaapps.com"
      ) {
        return "36px";
      } else {
        return "24px";
      }
    },
  },

  methods: {
    successReload() {
      location.reload();
    },

    async validate() {
      const areAllFieldsValid = await this.$refs.form.validate();

      if (!areAllFieldsValid) {
        return;
      }

      this.testConnection();
    },

    async testConnection() {
      this.isLoadingTest = true;
      const payload = {
        serverName: this.servername,
        userName: this.username,
        password: this.password,
      };

      const { error } = await auth.testDBConnection(payload);
      this.isLoadingTest = false;
      if (error) {
        this.testFailure = true;
        return;
      }
      this.testSuccess = true;
    },

    async updateConnection() {
      this.isLoadingDatabase = true;
      const payload = {
        serverName: this.servername,
        databaseName: this.dbname,
        userName: this.username,
        password: this.password,
      };

      const { error } = await auth.updateDBConnection(payload);
      this.isLoadingDatabase = false;
      if (error) {
        this.testFailure = true;
        return;
      }
      this.databaseSuccess = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#connection-layout {
  .footer {
    @extend .text-sm;
    color: var(--icon-color-inverted);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #toolbar {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0px 16px;

    .logo {
      display: flex;
      align-items: center;

      img {
        width: auto;
      }
    }

    .link {
      @extend .text-sm;
      color: var(--icon-color-inverted);
      margin-right: 16px;

      &:hover {
        color: var(--secondary);
        cursor: pointer;
      }
    }
  }
}
#db-connect {
  .description {
    color: var(--icon-color-inverted);
    margin-bottom: 4px;
  }

  .title {
    @extend .text-lg;
    font-weight: bold;
    color: var(--title-1-color);
    margin-bottom: 6px;
  }

  .connect-btn {
    min-height: 44px;
    width: 100%;
    margin-top: 8px;
  }
}

.test-in-btn {
  flex: 1;
  height: 44px;
  // padding: 0px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: $secondary !important;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;

  &:nth-child(2) {
    margin-left: 8px;
  }
}
</style>
