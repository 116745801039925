<template>
  <ValidationProvider
    v-slot="{ errors }"
    name="password"
    :rules="{ required: true, password: true }"
  >
    <PasswordField
      :value="value"
      label="password"
      is-mandatory
      :is-readonly="isLoading"
      :tooltip="_passwordHint"
      :error="errors[0]"
      class="q-mb-md"
      @input="handleInput"
      @enter="$emit('enter')"
    />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import PasswordField from "@/components/common/form/password-field/PasswordField.vue";

import { passwordHint } from "@/helpers/password-hint.js";

export default {
  name: "Password",

  components: { ValidationProvider, PasswordField },

  props: {
    value: {
      type: String,
      default: "",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    _passwordHint() {
      return passwordHint;
    },
  },

  methods: {
    handleInput(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped></style>
