import { render, staticRenderFns } from "./Connection.vue?vue&type=template&id=585df7ae&scoped=true&"
import script from "./Connection.vue?vue&type=script&lang=js&"
export * from "./Connection.vue?vue&type=script&lang=js&"
import style0 from "./Connection.vue?vue&type=style&index=0&id=585df7ae&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "585df7ae",
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace});
